// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'Elegan Minimalist',
		category: 'Desain Undangan Digital',
		img: require('@/assets/images/wedding1.jpg'),
	},
	{
		id: 2,
		title: 'Mewah Polaroid',
		category: 'Desain Undangan Digital',
		img: require('@/assets/images/wedding2.jpg'),
	},
	{
		id: 3,
		title: 'Klasik Blissful',
		category: 'Desain Undangan Digital',
		img: require('@/assets/images/wedding3.jpg'),
	},
	{
		id: 4,
		title: 'Modern Vogue',
		category: 'Desain Undangan Digital',
		img: require('@/assets/images/wedding4.jpg'),
	},
	{
		id: 5,
		title: 'Sederhana Lineart',
		category: 'Desain Undangan Digital',
		img: require('@/assets/images/wedding5.jpg'),
	},
	{
		id: 6,
		title: 'Vintage Velvet',
		category: 'Desain Undangan Digital',
		img: require('@/assets/images/wedding6.jpg'),
	}
	
];

export default projects;
